// @import "minima";

@font-face {
  font-family: eloquent;
  src: url(/assets/fonts/eloquent.woff);
}
@font-face {
  font-family: stem;
  src: url(/assets/fonts/Stem.woff);
}
@font-face {
  font-family: "auto-portage";
  src: url(/assets/fonts/auto-portage.woff);
}
@font-face {
  font-family: "steppsicons";
  src: url(/assets/fonts/stepps-icons.woff);
}
@font-face {
  font-family: "iconsmind";
  src: url(/assets/fonts/iconsmind.woff);
}


@font-face{
  font-family:'MetaNormalRomanRegular';
  font-weight:normal;
  src:url(/assets/fonts/meta-normal-roman-regular.woff2) format("woff2"),url(/assets/fonts/meta-normal-roman-regular.woff) format("woff");
}
@font-face{
  font-family:'MetaBoldRomanRegular';
  font-weight:bold;
  src:url(/assets/fonts/meta-bold-roman-regular.woff2) format("woff2"),url(/assets/fonts/meta-bold-roman-regular.woff) format("woff");
}
body {
  font-family: MetaNormalRomanRegular,"Trebuchet MS",Helvetica,Arial,sans-serif;
  color: black;
  margin:0;
}
li {
  list-style-type: none;
}
// start of nav styles
.element-invisible{position:absolute!important;clip:rect(1px 1px 1px 1px);clip:rect(1px,1px,1px,1px);overflow:hidden;height:1px;}
.clearfix:after{content:".";display:block;height:0;clear:both;visibility:hidden;}

.element-invisible{position:absolute!important;clip:rect(1px 1px 1px 1px);clip:rect(1px,1px,1px,1px);}
[class*=" icon-"],.login-to::before,.login-to::after{font-family:'auto-portage';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.icon-search:before{content:"\f002";}
.icon-envelope:before{content:"\e609";}
header{display:block;}
*{box-sizing:border-box;}
a{color:inherit;}
img{border:0;}
ul{list-style:disc;}
.clearfix::after{visibility:hidden;display:block;font-size:0;content:" ";clear:both;height:0;}
a{background:transparent;text-decoration:none;color:#595959;}
a:active,a:hover{outline:0;}
ul.menu{list-style-type:none;margin:0;padding:0;}
#block-menu-menu-header-links{clear:both;border-top:1px solid #E5E5E5;}
@media (min-width:48em){
#block-menu-menu-header-links{float:right;clear:none;}
}
#block-menu-menu-header-links ul.menu{padding:0;margin:0;float:right;}
#block-menu-menu-header-links ul.menu li{min-width:3em;float:left;position:relative;list-style:none;}
@media (min-width:48em){
#block-menu-menu-header-links ul.menu li{min-width:5.25em;}
}
#block-menu-menu-header-links ul.menu li a{line-height:2.8em;text-indent:-100px;border:0 solid #E5E5E5;border-left-width:1px;display:block;overflow:hidden;text-decoration:none;}
#block-menu-menu-header-links ul.menu li a:hover{background-color:#FAFAFA;}
@media (min-width:48em){
#block-menu-menu-header-links ul.menu li a{line-height:5.25em;}
}
#block-menu-menu-header-links ul.menu li a::before{position:absolute;color:#454545;font-size:1.1em;transition:color 100ms;left:118px;}
@media (min-width:48em){
#block-menu-menu-header-links ul.menu li a::before{left:136px;}
}
#block-menu-menu-header-links ul.menu li a:hover::before{color:black;}
#block-menu-menu-header-links ul.menu li ul{display:none;position:absolute;left:0;background-color:white;z-index:2000;border:1px solid #E5E5E5;border-top:none;box-shadow:3px 3px 10px 0 rgba(0,0,0,0.1);}
#block-menu-menu-header-links ul.menu li ul li{float:none;}
#block-menu-menu-header-links ul.menu li ul li a{text-indent:0;border-bottom-width:1px;border-left-width:0;white-space:nowrap;padding:0px 2rem;line-height:3;}
#block-menu-menu-header-links ul.menu li:hover ul{display:block;}
.login-to{padding:0 1em;}
.login-to::before{content:"\f007";margin-right:1em;}
.login-to::after{content:"\e603";margin-left:1em;}
.menu .nolink{border:0 solid #E5E5E5;border-left-width:1px;line-height:2.8em;display:block;}
@media (min-width:48em){
.menu .nolink{line-height:5.25em;}
}
#block-menu-menu-header-links:not(.fixed){right:auto!important;}
button,input{color:inherit;font:inherit;margin:0;}
button{overflow:visible;}
button{text-transform:none;}
button{-webkit-appearance:button;cursor:pointer;}
button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}
input{line-height:normal;}
.form-item{margin-bottom:1.5em;}
.form-item::after{content:" ";visibility:hidden;display:block;height:0;clear:both;}
.form-item label{margin:0 0 .25em;display:block;}
.form-item .form-text{max-width:360px;width:100%;}
input[type="text"]{border:none;border-top:3px solid #B7B7B7;background-color:#E5E5E5;padding:9px;-webkit-appearance:none;border-radius:0;-webkit-box-shadow:0 0 0px 1000px #E5E5E5 inset;}
header#header{height:8rem;position:relative;z-index:101;overflow: hidden;}
@media (min-width:48em){
header#header{height:5.25rem;}
}
.header__logo{float:left;margin:0.3em 2em 0.2em 2em;}
@media (min-width:50em){
.header__logo{margin:0.3em 2em 1.3em 2em;}
}
.header__logo img{height:4.375em;}
#block-search-api-page-search{display:none;position:absolute;background-color:white;padding:1.5rem;z-index:110;right:0;left:0;top:8rem;border-top:1px solid #E5E5E5;}
@media (min-width:30em){
#block-search-api-page-search{border-top:none;right:3rem;left:auto;top:8rem;}
}
@media (min-width:48em){
#block-search-api-page-search{right:5.22rem;top:5.25rem;border:1px solid #E5E5E5;}
}
.search-block-form{margin:0 auto;width:16.3rem;}
@media (min-width:30em){
.search-block-form{width:auto;}
}
.search-block-form .form-type-textfield{float:left;margin:0;width:14rem;}
@media (min-width:30em){
.search-block-form .form-type-textfield{width:17rem;}
}
.search-block-form .form-text{border:none;}
.search-block-form .button{background-color:#E5E5E5;border:none;color:#333333;padding:0.68rem;}

*{box-sizing:border-box;}
a{color:inherit;}
ul{list-style:disc;}
a{background:transparent;text-decoration:none;color:#595959;}
a:active,a:hover{outline:0;}
#navigation{position:absolute;z-index:102;top:5.2rem;}
@media (min-width:48em){
#navigation{top:0;width:100%;z-index:100;}
}
.hamburger{position:relative;width:2.8em;height:2.8em;padding:0.9em;cursor:pointer;background-color:black;}
@media (min-width:48em){
.hamburger{display:none;}
}
.hamburger .box{position:relative;display:block;width:16px;height:0;margin-top:8px;}
.hamburger span{transition:all ease-out 0.3s;display:block;position:absolute;width:100%;}
.hamburger .b{border-top:2px solid white;border-bottom:2px solid white;height:16px;top:-0.5em;}
.hamburger .a{margin-top:-1px;border-top:1px solid white;border-bottom:1px solid white;}
#block-system-main-menu{position:absolute;top:0;left:2.8em;padding:1.2em 1.6em;width:15em;height:0;overflow:hidden;-ms-transform:translateX(-9999px);transform:translateX(-9999px);transition:opacity 400ms ease-out;opacity:0;background-color:rgba(0,0,0,0.9);z-index:10;}
@media (min-width:40em){
#block-system-main-menu{margin-top:84px;padding:1.2em 2.4em;width:28em;}
}
@media (min-width:48em){
#block-system-main-menu{padding:0;left:0;width:100%;height:auto;opacity:1;-ms-transform:translate(0);transform:translate(0);background-color:white;overflow:visible;}
}
@media (min-width:48em){
#block-system-main-menu > .menu{display:-ms-flexbox;display:flex;border:1px solid #E5E5E5;border-left:none;border-right:none;}
}
#block-system-main-menu > .menu > .menu__item{display:inline-block;vertical-align:top;padding:0.8em 0;width:100%;border-top:1px solid #454545;}
@media (min-width:40em){
#block-system-main-menu > .menu > .menu__item{width:calc(49.99999% - 1.2em);margin:0 1em;}
}
@media (min-width:48em){
#block-system-main-menu > .menu > .menu__item{position:relative;width:auto!important;height:3.625rem;border:none;margin:0;padding:0;-ms-flex:1 0 auto;flex:1 0 auto;}
}
@media (min-width:48em) and (min-width:80em){
#block-system-main-menu > .menu > .menu__item + .menu__item{border-left:1px solid #E5E5E5;}
}
@media (min-width:80em){
#block-system-main-menu > .menu > .menu__item{-ms-flex:0 0 auto;flex:0 0 auto;}
}
#block-system-main-menu > .menu > .menu__item.first{width:100%;padding:1em 1em;border-bottom:1px solid #454545;margin:0;}
@media (min-width:48em){
#block-system-main-menu > .menu > .menu__item.first{border-bottom:none;padding:0;}
}
#block-system-main-menu > .menu > .menu__item.first a{color:white;}
@media (min-width:48em){
#block-system-main-menu > .menu > .menu__item.first a{color:black;}
#block-system-main-menu > .menu > .menu__item.first a:hover,#block-system-main-menu > .menu > .menu__item.first a.active{color:#595959;}
#block-system-main-menu > .menu > .menu__item.first a:hover::before,#block-system-main-menu > .menu > .menu__item.first a.active::before{color:#595959;}
}
@media (min-width:80em){
#block-system-main-menu > .menu > .menu__item.last{border-right:1px solid #E5E5E5;}
}
#block-system-main-menu > .menu > .menu__item:nth-of-type(-n+2){border-top:none;}
@media (min-width:40em) and (max-width:47.99em){
#block-system-main-menu > .menu > .menu__item:nth-of-type(-n+3){border-top:none;}
#block-system-main-menu > .menu > .menu__item:nth-of-type(2n){margin-left:0;}
#block-system-main-menu > .menu > .menu__item:nth-of-type(2n+1){margin-right:0;}
}
@media (min-width:48em){
#block-system-main-menu > .menu > .menu__item > .menu{display:none;}
}
#block-system-main-menu > .menu > .menu__item > a{color:#fff;}
@media (min-width:48em){
#block-system-main-menu > .menu > .menu__item > a{color:black;line-height:3.625rem;text-align:center;}
#block-system-main-menu > .menu > .menu__item > a:hover{color:#595959;}
}
@media (min-width:80em){
#block-system-main-menu > .menu > .menu__item > a{padding:0 2rem;}
}
@media (min-width:48em){
#block-system-main-menu > .menu > .menu__item.expanded > a::after{content:"\e603";position:relative;bottom:-0.125em;color:#595959;}
}
@media (min-width:48em){
#block-system-main-menu > .menu > .menu__item:hover::after{content:'';display:block;position:absolute;left:0;bottom:0;right:0;border-bottom:3px solid #595959;}
}
@media (min-width:48em){
#block-system-main-menu > .menu > .menu__item:hover > .menu{display:block;position:absolute;left:0;top:3.625rem;border:1px solid #E5E5E5;border-top:none;background-color:white;box-shadow:3px 3px 10px 0 rgba(0,0,0,0.1);}
}
@media (min-width:48em){
#block-system-main-menu > .menu > .menu__item:hover > .menu > .menu__item{box-sizing:border-box;min-width:12.5em;border-top:1px solid #E5E5E5;}
}
@media (min-width:48em){
#block-system-main-menu > .menu > .menu__item:hover > .menu > .menu__item > a{padding:0 2rem;height:2.75rem;line-height:2.75rem;}
}
#block-system-main-menu a{color:white;text-decoration:none;font-size:1.125em;line-height:1.5;white-space:nowrap;}
@media (min-width:48em){
#block-system-main-menu a{display:block;font-size:1em;color:black;}
#block-system-main-menu a:hover{color:#595959;}
}
#block-system-main-menu a.active{font-weight:bold;text-decoration:underline;}
@media (min-width:48em){
#block-system-main-menu a.active{text-decoration:none;}
}
ul.menu{list-style-type:none;margin:0;padding:0;}
.menu-open #block-system-main-menu{opacity:1;height:auto;-ms-transform:translate(0);transform:translate(0);}
.hamburger.fixed{position:fixed;top:0;}
@media (min-width:48em){
#block-system-main-menu.fixed{margin-top: 0;position:fixed;top:0;left:0;right:0;}
}

[class^="icon-"], [class*=" icon-"], .button--secondary::after, .button--tertiary::after, .cta-tertiary::after, .node-readmore a::after, #block-system-main-menu > .menu > .menu__item.first a::before, #block-system-main-menu > .menu > .menu__item.expanded > a::after, .login-to::before, .login-to::after, .select-wrap:after, .social a::after, .view-supplier .field-name-field-preferred::after {
    font-family: 'auto-portage';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#block-system-main-menu > .menu > .menu__item.first a::before {
    content: "\e900";
    margin-right: 0.25em;
}
@media (min-width: 48em){
#block-system-main-menu > .menu > .menu__item.first a::before {
    position: relative;
    color: black;
}}
@media (min-width: 48em){
#block-system-main-menu > .menu > .menu__item.first a:hover::before, #block-system-main-menu > .menu > .menu__item.first a.active::before {
    color: #595959;
}}
#block-menu-menu-header-links ul.menu li a::before {
    position: absolute;
    font-family: 'auto-portage';
    color: #595959;
    font-size: 1.1em;
    transition: color 100ms;
    left: 118px;
}
@media (min-width: 48em){
#block-menu-menu-header-links ul.menu li a::before {
    left: 136px;
}}
.phone-icon:before {
    font-family: FontAwesome !important;
}
.envelope-icon:before {
    content: "\e609";
}
@media (min-width: 48em){
#block-system-main-menu > .menu > .menu__item > a.active {
    color: #595959;
}}
// end of nav styles


// start of flipper menu
@media all{
*{box-sizing:border-box;}
.bg-custom-image{background-size:cover;background-position:center center;min-height:70vh;}
.bg-custom-image h1 {
  font-family: eloquent,times,Helvetica,sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin-top: 35vh;
  text-shadow: 1px 1px 2px #fff;
}
@media(max-width: 450px){
  .bg-custom-image h1 {
    margin-top: 30vh;
  }
}
@media (max-width:79.99em){
.bg-custom-image{padding-top:16%;padding-bottom:16%;}
}
@media (min-width:80em){
.bg-custom-image{min-height:70vh;}
}
.front .bg-custom-image{min-height:19rem;}
@media (min-width:40em){
.front .bg-custom-image{min-height:26rem;}
}
@media (min-width:64em){
.front .bg-custom-image{min-height:31.25rem;}
}
.field-name-field-paragraphs li.page-section:first-child::before{display:none!important;}
.page-section{position:relative;}
.page-section::after{visibility:hidden;display:block;font-size:0;content:" ";clear:both;height:0;}
.entity-paragraphs-item{padding:1rem;}
.entity-paragraphs-item::after{visibility:hidden;display:block;font-size:0;content:" ";clear:both;height:0;}
@media (min-width:30em){
.entity-paragraphs-item{padding:1.5rem 1.5rem;}
}
@media (min-width:40em){
.entity-paragraphs-item{padding:2rem 2rem;}
}
@media (min-width:48em){
.entity-paragraphs-item{padding:3rem;}
}
@media (min-width:64em){
.entity-paragraphs-item{padding:1.5rem 8rem;}
}
}

@media all{
*{box-sizing:border-box;}
a{color:inherit;}
ul{list-style:disc;}
a{background:transparent;text-decoration:none;color:#595959;}
a:active,a:hover{outline:0;}
.card-stack{display:block;text-align:left;transform-style:preserve-3d;list-style-type:none;padding:0 1em;text-align:center;}
@media (min-width:30em){
.card-stack{padding:0;}
}
@media (min-width:48em){
.card-stack{margin:-2em 5em 0;}
}
.card-stack li{position:relative;display:inline-block;margin:0 -.25em 0 0;z-index:1;font-size:1.25em;transition:left 0.75s ease-in-out;padding:.5em;vertical-align:top;}
@media (min-width:40em){
.card-stack li{padding:0;}
}
@media (min-width:48em){
.card-stack li{margin:0 -1.125em;}
}
.card-stack a{display:table-cell;vertical-align:middle;padding:1em;width:8em;height:8em;font-size:.75em;text-decoration:none;color:white;transition:all 0.25s;min-height:8em;background:#454545;}
@media (min-width:40em){
.card-stack a{background:black;}
}
@media (min-width:48em){
.card-stack a{font-size:1em;-ms-transform:translateX(0) scale(0.75);transform:translateX(0) scale(0.75);}
}
.card-stack a::before{position:absolute;left:50%;top:100%;margin-left:-0.5em;width:0;height:0;border-left:0.5em solid transparent;border-right:0.5em solid transparent;border-top:0.5em solid #00b3be;opacity:0;-ms-transform:translateY(-100%);transform:translateY(-100%);content:'';}
.card-stack [data-interaction-distance='-1']{z-index:2;}
.card-stack [data-interaction-distance='-1'] a{background:#454545;}
.card-stack [data-interaction-distance='0']{z-index:3;}
.card-stack [data-interaction-distance='0'] a{background:#00b3be;color: #fff;}
.card-stack [data-interaction-distance='0'] ~ li{z-index:1;}
.card-stack [data-interaction-distance='0'] + li{z-index:2;}
.card-stack [data-interaction-distance='0'] + li a{background:#454545;}
@media (min-width:50em){
.card-stack [data-interaction-distance='-2'] a{-ms-transform:translateX(-1.95em) scale(0.75);transform:translateX(-1.95em) scale(0.75);}
.card-stack [data-interaction-distance='-1'] a{-ms-transform:translateX(-1.5em) scale(0.875);transform:translateX(-1.5em) scale(0.875);}
.card-stack [data-interaction-distance='0'] a{-ms-transform:scale(1);transform:scale(1);}
.card-stack [data-interaction-distance='0'] ~ li a{-ms-transform:translateX(1.95em) scale(0.75);transform:translateX(1.95em) scale(0.75);}
.card-stack [data-interaction-distance='0'] + li a{-ms-transform:translateX(1.5em) scale(0.875);transform:translateX(1.5em) scale(0.875);}
}
.content-flipper-menu{margin-bottom:-26.2rem;z-index:10;position:relative;top:-3rem;}
@media (min-width:30em){
.content-flipper-menu{margin-bottom:-17.5rem;top:-4rem;}
}
@media (min-width:40em){
.content-flipper-menu{margin-bottom:-10rem;top:-6rem;}
}
@media (min-width:48em){
.content-flipper-menu{top:-5rem;padding:0 4rem;}
}
.content-flipper-menu .item-list{position:relative;}
.content-flipper-menu .item-list ul{max-width:20rem;margin:0 auto;}
@media (min-width:30em){
.content-flipper-menu .item-list ul{max-width:none;}
}
}
// end of flipper menu


// start of covid-19 styles
.content-flipper-menu + .page-section{padding-top:23rem;background-color: rgb(229, 229, 229);background-image: url(/assets/images/shards-bright.svg);background-size: cover;}
@media (min-width:30em){
.content-flipper-menu + .page-section{padding-top:14rem;}
}
@media (min-width:40em){
.content-flipper-menu + .page-section{padding-top:5rem;}
}
@media (min-width:64em){
.content-flipper-menu + .page-section{padding-top:6rem;}
}
@media (max-width:39.99em){
.paragraphs-item-two-column .content{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-direction:column;flex-direction:column;}
}
.paragraphs-item-two-column .content .field{width:45%;}
@media (max-width:39.99em){
.paragraphs-item-two-column .content .field{width:100%;}
}
.paragraphs-item-two-column .content .field-name-field-top-right-content{float:right;}
@media (max-width:39.99em){
.paragraphs-item-two-column .content .field-name-field-top-right-content{-ms-flex-order:1;order:1;}
}
.paragraphs-item-two-column .content .field-name-field-middle-left-content{float:left;padding-right:1rem;}
@media (max-width:39.99em){
.paragraphs-item-two-column .content .field-name-field-middle-left-content{-ms-flex-order:2;order:2;}
}
.field-name-field-paragraphs li.content-flipper-menu + li.page-section::before{display:none!important;}
.page-section{position:relative;}
.page-section::after{visibility:hidden;display:block;font-size:0;content:" ";clear:both;height:0;}
.page-section img{max-width:100%;height:auto!important;position:relative;z-index:10;}
.entity-paragraphs-item{padding:1rem;}
.entity-paragraphs-item::after{visibility:hidden;display:block;font-size:0;content:" ";clear:both;height:0;}
@media (min-width:30em){
.entity-paragraphs-item{padding:1.5rem 1.5rem;}
}
@media (min-width:40em){
.entity-paragraphs-item{padding:2rem 2rem;}
}
@media (min-width:48em){
.entity-paragraphs-item{padding:3rem;}
}
@media (min-width:64em){
.entity-paragraphs-item{padding:1.5rem 8rem;}
}
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 3){background-color:#E5E5E5;background-image:url(/assets/images/shards-bright.svg);background-size:cover;}
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 3)::before{background:url(/assets/images/angle-br-white.svg) repeat scroll 0 0/100% 100% transparent;content:"";display:block;position:relative;top:0;width:100%;height:50px;visibility:visible;background:url(/assets/images/angle-tr-white.svg) repeat scroll 0 0/100% 100% transparent;}
@media (min-width:30em){
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 3)::before{width:960px;height:100px;}
}
@media (min-width:40em){
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 3)::before{width:1440px;height:150px;}
}
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 3)::after{background:url(/assets/images/angle-br-white.svg) repeat scroll 0 0/100% 100% transparent;content:"";display:block;position:relative;top:0;width:100%;height:50px;visibility:visible;}
@media (min-width:30em){
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 3)::after{width:960px;height:100px;}
}
@media (min-width:40em){
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 3)::after{width:1440px;height:150px;}
}
.cta-secondary {
  text-decoration: none;
  transition: color ease-out .1s;
  cursor: pointer;
}
.cta-secondary::after {
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  margin-left: .5em;
  display: inline-block;
  content: '>';
  background-color: #00b3be;
  color: white;
  transition: background-color ease-out .1s;
}

.page-section::after {
    visibility: hidden;
    display: block;
    font-size: 0px;
    content: " ";
    clear: both;
    height: 0px;
}
.content-flipper-menu + .page-section::after {
  content: "";
  display: block;
  position: relative;
  top: 0px;
  width: 100%;
  height: 50px;
  visibility: visible;
  background: url(/assets/images/angle-br-white.svg) 0px 0px / 100% 100% repeat scroll transparent;
}
@media (min-width: 30em){
.content-flipper-menu + .page-section::after {
  width: 960px;
  height: 100px;
}}
@media (min-width: 40em){
.content-flipper-menu + .page-section::after {
  width: 1440px;
  height: 150px;
}}




h2 {
  font-size: 2.4em;
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: normal;
}
@media (min-width: 48em){
  h2 {
      font-size: 2.8em;
  }
}
@media (min-width: 64em){
  h2 {
      font-size: 3.2em;
  }
}
// end of covid-19 styles



// start of home valuation
@media all{
.cta-secondary{text-decoration:none;transition:color ease-out .1s;cursor:pointer;}
.cta-secondary::after{width:1.5em;height:1.5em;line-height:1.5em;text-align:center;margin-left:.5em;display:inline-block;content:'>';background-color:#595959;color:white;transition:background-color ease-out .1s;}
.cta-secondary:hover::after{background:#00b3be!important;}
.page-section{position:relative;}
.page-section::after{visibility:hidden;display:block;font-size:0;content:" ";clear:both;height:0;}
.entity-paragraphs-item{padding:1rem;}
.entity-paragraphs-item::after{visibility:hidden;display:block;font-size:0;content:" ";clear:both;height:0;}
@media (min-width:30em){
.entity-paragraphs-item{padding:1.5rem 1.5rem;}
}
@media (min-width:40em){
.entity-paragraphs-item{padding:2rem 2rem;}
}
@media (min-width:48em){
.entity-paragraphs-item{padding:3rem;}
}
@media (min-width:64em){
.entity-paragraphs-item{padding:1.5rem 8rem;}
}
.bg-white{background-color:white;}
}
// end of home valuation



// start of paragraph video
.paragraphs-item-video{text-align:center;}
.paragraphs-item-video iframe{max-width:100%;}
@media(max-width:700px){
  .paragraphs-item-video iframe{height:200px;}
}
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 5){background-color:#00b3be;background-image:url(/assets/images/shards-bright.svg);background-size:cover;color:white;}
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 5)::before{background:url(/assets/images/angle-br-white.svg) repeat scroll 0 0/100% 100% transparent;content:"";display:block;position:relative;top:0;width:100%;height:50px;visibility:visible;background:url(/assets/images/angle-tr-white.svg) repeat scroll 0 0/100% 100% transparent;}
@media (min-width:30em){
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 5)::before{width:960px;height:100px;}
}
@media (min-width:40em){
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 5)::before{width:1440px;height:150px;}
}
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 5)::after{background:url(/assets/images/angle-tr-white.svg) repeat scroll 0 0/100% 100% transparent;content:"";display:block;position:relative;top:0;width:100%;height:50px;visibility:visible;}
@media (min-width:30em){
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 5)::after{width:960px;height:100px;}
}
@media (min-width:40em){
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 5)::after{width:1440px;height:150px;}
}
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 5) a,.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 5) p{color:white;}
.field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n + 5) a{color:white;}
li.page-section.type-video.bg-auto {
  background-color: #00b3be;
  background-image: url(/assets/images/shards-bright.svg);
}

#about-us::after {
  content: "";
  display: block;
  position: relative;
  top: 0px;
  width: 100%;
  height: 50px;
  visibility: visible;
  background: url(/assets/images/angle-br-white.svg) 0px 0px / 100% 100% repeat scroll transparent;
}
@media (min-width: 30em){
#about-us::after {
  width: 960px;
  height: 100px;
}}
@media (min-width: 40em){
#about-us::after {
  width: 1440px;
  height: 150px;
}}

#about-us::before {
    content: "";
    display: block;
    position: relative;
    top: 0px;
    width: 100%;
    height: 50px;
    visibility: visible;
    background: url(/assets/images/angle-tr-white.svg) 0px 0px / 100% 100% repeat scroll transparent;
}
@media (min-width: 30em){
#about-us::before, .field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n+7)::before {
    width: 960px;
    height: 100px;
}}
@media (min-width: 40em){
#about-us::before {
    width: 1440px;
    height: 150px;
}}
// end of paragraph video


// start of testimonials styles
.bg-grey_shards {
    background-color: #E5E5E5;
    background-image: url(/assets/images/shards-bright.svg);
    background-size: cover;
}
.bg-grey_shards::after {
  content: "";
  display: block;
  position: relative;
  top: 0px;
  width: 100%;
  height: 50px;
  visibility: visible;
  background: url(/assets/images/angle-br-white.svg) 0px 0px / 100% 100% repeat scroll transparent;
}
@media (min-width: 30em){
.bg-grey_shards::after {
  width: 960px;
  height: 100px;
}}
@media (min-width: 40em){
.bg-grey_shards::after {
  width: 1440px;
  height: 150px;
}}

.bg-grey_shards::before {
    content: "";
    display: block;
    position: relative;
    top: 0px;
    width: 100%;
    height: 50px;
    visibility: visible;
    background: url(/assets/images/angle-tr-white.svg) 0px 0px / 100% 100% repeat scroll transparent;
}
@media (min-width: 30em){
.bg-grey_shards::before, .field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n+7)::before {
    width: 960px;
    height: 100px;
}}
@media (min-width: 40em){
.bg-grey_shards::before {
    width: 1440px;
    height: 150px;
}}

.button--primary, .top-teaser .node-readmore a {
    padding: .625em 2.5em .625em 1.5em;
    display: inline-block;
    position: relative;
    background-color: #333333;
    background: linear-gradient(to bottom,transparent 50%,black 50%) repeat scroll 0 0 #333333;
    border: 0;
    color: white;
    box-shadow: 0.2em 0.2em 0 0 rgba(0,0,0,0.2);
    font-size: 1.125em;
    text-decoration: none;
    transition: background-color ease-out 100ms;
}
@media (min-width: 48em){
.button--primary, .top-teaser .node-readmore a {
    padding: .875em 3em .875em 2em;
}}
.button--primary::after, .top-teaser .node-readmore a::after {
    font-family: 'auto-portage';
    content: "\e605";
    color: #595959;
    position: absolute;
    right: 0.35em;
    font-size: 1.4em;
    top: 0.55em;
}


.customTestimonials-content{max-width:900px;margin:auto;}
.customTestimonials{background: none;background-size:cover;display:inline-block;padding-top:60px;padding-bottom:60px;width:100%;}
.customTestimonials-content{text-align:center;padding:20px;}
.customTestimonials-content p,.customTestimonials-content h2{color:#000!important;}

.slick-slider{position:relative;display:block;box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent;}
.slick-list{position:relative;display:block;overflow:hidden;margin:0;padding:0;}
.slick-list:focus{outline:none;}
.slick-slider .slick-track,.slick-slider .slick-list{-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);-ms-transform:translate3d(0, 0, 0);-o-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);}
.slick-track{position:relative;top:0;left:0;display:block;margin-left:auto;margin-right:auto;}
.slick-track:before,.slick-track:after{display:table;content:'';}
.slick-track:after{clear:both;}
.slick-slide{display:none;float:left;height:100%;min-height:1px;}
.slick-initialized .slick-slide{display:block;}

.customTestimonials{background:none;padding-top:60px;padding-bottom:60px;width:100%;}
.customTestimonials-content{text-align:center;padding:20px;}
.customTestimonials-content h2 {margin-bottom: 0;}
.customTestimonials-content p,.customTestimonials-content h2{color:#000!important;}
.customTestimonials-content p{font-family:'Lato', sans-serif;padding:0 8px;font-size:17px;line-height:30px;font-weight:300;color:#666666;text-align:justify;text-align-last:center;}
.customTestimonials-content .tile{padding:0px 50px 0 50px;}
@media only screen and (max-width: 737px){
  .customTestimonials-content .tile{padding:0px 0px 0 0px;}
}
@media only screen and (min-width:30em) and (max-width: 46em){
.customTestimonials-content p{font-size:22px!important;line-height:26px!important;}
}
@media only screen and (max-width: 30em){
.customTestimonials-content p{font-size:14px!important;line-height:24px!important;}
}
.slick-prev,.slick-next{font-size:0;line-height:0;position:absolute;top:50%;display:block;width:30px;height:60px;padding:0;-webkit-transform:translate(0, -50%);-ms-transform:translate(0, -50%);transform:translate(0, -50%);cursor:pointer;color:transparent;border:none;outline:none;background:transparent;display:none!important;}
.slick-prev:hover,.slick-prev:focus,.slick-next:hover,.slick-next:focus{color:transparent;outline:none;background:transparent;}
.slick-prev:hover:before,.slick-prev:focus:before,.slick-next:hover:before,.slick-next:focus:before{opacity:1;}
.slick-prev:before,.slick-next:before{font-family:FontAwesome;font-size:80px;line-height:1;opacity:.75;color:#000;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;display:none;}
@media only screen and (max-width: 46.01em){
.slick-prev:before,.slick-next:before{font-size:60px;}
}
@media only screen and (max-width: 500px){
.slick-prev:before,.slick-next:before{font-size:40px;}
}
.slick-prev{left:-55px;}
.slick-prev:before{content:'\f104';}
.slick-next{right:-55px;}
.slick-next:before{content:'\f105';}
@media only screen and (max-width: 46.01em){
.slick-prev{left:-35px;}
.slick-next{right:-35px;}
}
@media only screen and (max-width: 500px){
.slick-prev{left:-25px;}
.slick-next{right:-25px;}
}
.slick-dotted.slick-slider{margin-bottom:30px;}
.slick-dots{position:absolute;bottom:-25px;display:block;width:100%;padding:0;margin:0;list-style:none;text-align:center;}
.slick-dots li{position:relative;display:inline-block;width:20px;height:20px;margin:0 5px;padding:0;cursor:pointer;}
.slick-dots li button{font-size:0;line-height:0;display:block;width:20px;height:20px;padding:5px;cursor:pointer;color:transparent;border:0;outline:none;background:transparent;}
.slick-dots li button:hover,.slick-dots li button:focus{outline:none;}
.slick-dots li button:hover:before,.slick-dots li button:focus:before{opacity:1;}
.slick-dots li button:before{font-family:'slick';font-size:50px;line-height:20px;position:absolute;top:0;left:0;width:20px;height:20px;content:'\2022';text-align:center;opacity:.25;border-radius:50%;border:solid 1px #000;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.slick-dots li.slick-active button:before{opacity:.75;color:#000;}

.customIntro-content{max-width:1260px;margin:auto;}
.stars-wrapper {margin-top: 20px;color: #00b3be;font-size: 25px;}
// end of testimonials styles


// start of our reach styles
.paragraphs-item-two-column .content .field-name-field-bottom-right-content{float:right;}
@media (min-width:40em){
.paragraphs-item-two-column .content .field-name-field-bottom-right-content{padding-left:1rem;}
}
@media (max-width:39.99em){
.paragraphs-item-two-column .content .field-name-field-bottom-right-content{-ms-flex-order:3;order:3;}
}
.flip-column-sides .paragraphs-item-two-column .content .field-name-field-top-right-content,.flip-column-sides .paragraphs-item-two-column .content .field-name-field-bottom-right-content{float:left;}
.flip-column-sides .paragraphs-item-two-column .content .field-name-field-middle-left-content{float:right;}
// .bg-grey_shards{background-color:#E5E5E5;background-image:url(/assets/images/shards-bright.svg);background-size:cover;}
.info-btn {
  background: #000;
  color: #fff;
  font-size: 14px;
  letter-spacing: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
  padding: 16px 22px;
  transition: all 0.15s cubic-bezier(0.25, 1, 0.33, 1) 0s;
  border-radius: 3px;
}
.info-btn:hover {
  background: #00b3be;
  color: #fff;
  text-decoration: none;
}
// end of our reach styles


// start of footer styles
ul.menu{list-style-type:none;margin:0;padding:0;}
footer{position:relative;background:#fdfdfd;}
.region-footer{padding-bottom:2em;}
.region-footer .block{text-align:center;clear:both;}
@media (min-width:48em){
.region-footer .block{float:left;width:50%;clear:none;}
}
.region-footer .block h3{margin-bottom:1em;color:#000;}
.region-footer .block p{position:relative;top:-12px;color:#454545;font-size:2em;}
.region-footer .block p a{text-decoration:none;}
.region-footer p.mobile-tel-link{position:relative;top:-30px;color:#000;}
.region-footer .logo-inverted{height:4.375em;margin-top:1em;display:block;margin:20px auto;}
.region-footer-lower{background:#454545;overflow:hidden;padding-bottom:2em;padding-left:2rem;padding-right:2rem;}
.region-footer-lower-wrapper {
  max-width: 1140px;
  margin: auto;
}
@media (min-width:48em){
.region-footer-lower{padding-left:4rem;padding-right:4rem;}
}
.region-footer-lower .block{width:100%;padding:1.5em 0.8em 0;}
@media (min-width:30em){
.region-footer-lower .block{width:50%;float:left;}
}
@media (min-width:48em){
.region-footer-lower .block{width:33%;}
}
.region-footer-lower a,.region-footer-lower p{color:#909090;text-decoration:none;font-size:0.8em;}
.social a{margin-right: 15px;}
.social a:hover{color:#454545;}
[class*=grid-unit]{width:100%;position:relative;padding:1.5em 0.8em 0;}
@media (min-width:40em){
[class*=grid-unit]{float:left;}
}
@media (min-width:64em){
[class*=grid-unit]{padding:0 3em;}
}
.grid-unit-alpha{width:100%;}
.back-to-top{position:fixed;opacity:0;right:2rem;bottom:2rem;font-size:2.25rem;line-height:2rem;transition:all 0.2s ease-out;color:#666666;cursor:pointer;z-index:1;}
.back-to-top:hover{color:black;}
.back-to-top.active{opacity:1;}
.back-to-top.static{position:absolute;z-index:1;top:25px;right:25px;color:#666666;display:none;}
.back-to-top.static:hover{color:white;}
.back-to-top.static.active{opacity:1;display:block;}
@media (max-width:89.99em){
.back-to-top.static{opacity:1;display:block;}
}
.region-footer-lower .block h5 {
    color: #fff;
}
// end of footer styles;


// stats
img{max-width:100%;}
.wrap{width:1020px;margin:0 auto;}
.prowess{color:#fff;background-color: #000;}
#svg{transform:rotate(90deg) scaleX(-1);}
#svg circle{stroke-dashoffset:0;transition:stroke-dashoffset 2s linear;transition-timing-function:ease-out;stroke:#00b3be;stroke-width:1em;}
#svg #bar{stroke:#1e1e1e;stroke-width:1.1em;}
#cont{display:block;height:200px;width:200px;border-radius:100%;position:relative;vertical-align:middle;display:inline-block;}
#cont:after{position:absolute;display:block;height:160px;width:160px;left:50%;top:50%;content:attr(data-pct)"%";margin-top:-80px;margin-left:-80px;border-radius:100%;line-height:160px;text-align:center;text-shadow:0 0 0.5em black;font-size:7rem;font-weight:normal;}
.orange-border-content{border:2px solid #00b3be;padding:30px;margin-bottom:60px;}
.full-orange{background-color:#00b3be;padding:30px;margin-bottom:10px;}
.footer-subheading{text-align:center;font-size:1.5rem;}
.large-subheading{font-size:4rem;}
.orange{color:#00b3be;}
.divider{width:100%;border-bottom:1px solid;margin:10px 0px;}
.data-wrap{overflow:hidden;}
#no1{text-align:center;}
.clearance-label{padding:0px 30px;margin-top:40px;}
.stats-row{margin-top:40px;}
.house-types-container{text-align:left;margin:0 auto;text-transform:uppercase;}
.sold-row{margin:20px 0px;}
#lowest-sale-price{border-right:1px solid #00b3be;}
#highest-sale-price,#lowest-sale-price{padding:20px 0px;}
#sales-in{margin:10px;text-align:center;font-size:2.5rem;text-transform:uppercase;}
.sale-price{margin-bottom:10px;}
#property-count{margin-bottom:10px;}
#orange-house{text-align:center;margin-bottom:10px;}
.stats{padding-bottom:50px;padding-top: 70px;}
.orange.footer-subheading.medium-subheading.footer-award{font-size:1.5rem;line-height:2;}
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape){
.wrap{width:95%;margin:0 auto;}
}
@media screen and (max-width: 980px){
.wrap{width:95%;margin:0 auto;}
}
@media only screen and (max-width:767px){
.wrap{width:95%;margin:0 auto;}
}
@media only screen and (max-width: 768px){
.regular-content{text-align:center;}
#cont{margin:0 auto;display:block;}
.footer-subheading img{width:64px;max-width:20px;}
#lowest-sale-price{border-right:none;}
}
@media only screen and (max-width: 768px){
.medium-subheading{font-size:1.5rem;}
.large-subheading{font-size:2.5rem;}
.full-orange{margin-top:20px;}
.orange-border-content{margin-bottom:20px;}
.clearance-label{margin-top:20px;}
.stats.data-wrap{padding:20px;}
div#lowest-sale-price{padding:0;}
div#highest-sale-price{padding:0;}
div#orange-house{margin-top:50px;}
}
@media only screen and (max-width: 425px){
.clearance-label{padding:0;}
}
img{border:0;}
svg:not(:root){overflow:hidden;}
@media print{
*{color:#000!important;text-shadow:none!important;background:transparent!important;-webkit-box-shadow:none!important;box-shadow:none!important;}
img{page-break-inside:avoid;}
img{max-width:100%!important;}
}
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
*:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
img{vertical-align:middle;}
.row{margin-right:-15px;margin-left:-15px;}
.col-sm-3,.col-md-5,.col-sm-6,.col-md-7,.col-xs-12{position:relative;min-height:1px;padding-right:15px;padding-left:15px;}
.col-xs-12{float:left;}
.col-xs-12{width:100%;}
@media (min-width: 768px){
.col-sm-3,.col-sm-6{float:left;}
.col-sm-6{width:50%;}
.col-sm-3{width:25%;}
}
@media (min-width: 992px){
.col-md-5,.col-md-7{float:left;}
.col-md-7{width:58.33333333%;}
.col-md-5{width:41.66666667%;}
}
.row:before,.row:after{display:table;content:" ";}
.row:after{clear:both;}
img{border:0;}
svg:not(:root){overflow:hidden;}
@media print{
*{color:#000!important;text-shadow:none!important;background:transparent!important;-webkit-box-shadow:none!important;box-shadow:none!important;}
img{page-break-inside:avoid;}
img{max-width:100%!important;}
}
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
:before,:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
img{vertical-align:middle;}
.row{margin-right:-15px;margin-left:-15px;}
.col-sm-3,.col-md-5,.col-sm-6,.col-md-7,.col-xs-12{position:relative;min-height:1px;padding-right:15px;padding-left:15px;}
.col-xs-12{float:left;}
.col-xs-12{width:100%;}
@media (min-width:768px){
.col-sm-3,.col-sm-6{float:left;}
.col-sm-6{width:50%;}
.col-sm-3{width:25%;}
}
@media (min-width:992px){
.col-md-5,.col-md-7{float:left;}
.col-md-7{width:58.33333333%;}
.col-md-5{width:41.66666667%;}
}
.row:before,.row:after{display:table;content:" ";}
.row:after{clear:both;}
.sold-row.row {
    display: flex;
    align-items: center;
    justify-content: center;
}
.col-sm-3.medium-subheading.footer-subheading.orange {
  width:30%;
}
.col-sm-3.medium-subheading.footer-subheading.orange img {
    max-width: 50px;
}
.col-sm-3.medium-subheading.footer-subheading.orange i {
    font-size: 40px;
    color:#fff;
}
.medium-subheading.footer-subheading.col-sm-6 {
    font-size: 100%;
}
@media(max-width:700px){
  .medium-subheading.footer-subheading.col-sm-6 {
    font-size:60%;
    width: 60%;
  }
  .col-sm-3.medium-subheading.footer-subheading.orange {
    font-size:90%;
    width: 33%;
  }
  .col-sm-3.medium-subheading.footer-subheading.orange i {
    font-size: 25px;
  }
}

.prowess::before {
    content: "";
    display: block;
    position: relative;
    top: 0px;
    width: 100%;
    height: 50px;
    visibility: visible;
    background: url(/assets/images/angle-tr-white.svg) 0px 0px / 100% 100% repeat scroll transparent;
}
@media (min-width: 30em){
.prowess::before, .field-name-field-paragraphs.has-banner.has-content-flipper-menu li.bg-auto:nth-child(6n+7)::before {
    width: 960px;
    height: 100px;
}}
@media (min-width: 40em){
.prowess::before {
    width: 1440px;
    height: 150px;
}}
// end of stats styles


// about
.about-jason {
  background-color: #E5E5E5;
  background-image: url(/assets/images/shards-bright.svg);
  background-size: cover;
}
.about-jason-wrapper{
  max-width:1140px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-jason-wrapper .r-col-6{
  width:50%;
  float:left;
}
.about-jason h2 {
  margin-bottom: 10px
}
.about-jason h3 {
  margin-top: 0;
}
.about-jason p {
  text-align: justify;
}
img.agent-image {
    max-width: 500px;
    margin: 50px 0;
}
@media(max-width: 700px){
  .about-jason-wrapper{
    padding: 0 1rem 5rem;
    display:block;
  }
  .about-jason-wrapper .r-col-6{
    width:100%;
    float:none;
  }
  .about-jason img {
    max-height: 400px;
    object-fit: cover;
  }
  .about-jason p {
    text-align: left;
  }
  .about-jason iframe {
    max-width: 100%;
  }
}
.about-us .prowess::before{
  display:none;
}
.about-us #testimonials::before{
  display:none;
}
.about-us #testimonials::after{
  display:none;
}
.about-us li#testimonials {
    background: linear-gradient(45deg, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url(/assets/images/white-wooden-cupboards-2724749.jpg) 50% 50%;
    background-size: cover;
}
.about-us li#testimonials .customTestimonials-content p, .about-us li#testimonials .customTestimonials-content h2 {
  color:#fff!important;
}
.about-us li#testimonials .slick-dots li button:before {
  border: solid 1px #fff;
}
.about-us li#testimonials .slick-dots li.slick-active button:before {
  color: #fff;
}
.about-us li#testimonials .stars-wrapper {
  color:#00b3be;
}
// end of about page styles

span.counter, .mobile-tel-link {
  font-family: stem;
}
